<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
/**
 * form example
 * {
    "icp_subject": {
        "base_info": {
            "type": 4, // 企业类型
            "name": "李四王五有限责任公司",
            "province": "440000", // 广东省
            "city": "440100", // 广州市
            "district": "440105", // 海珠区
            "address": "新港中路TIT创意园B6栋",
            "comment": "主体备注"
        },
        "organize_info": {
            "certificate_type": 1, // 营业执照
            "certificate_number": "92111122223333443F",
            "certificate_address": "广东省广州市海珠区新港中路TIT创意园B6栋",
            "certificate_photo": "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7"
        },
        "principal_info": {
            "name": "李四",
            "mobile": "13022222221",
            "email": "lisi@lisiwangwucorp.com",
            "emergency_contact": "1722222234",
            "certificate_type": 2, // 身份证
            "certificate_number": "440105198802023334",
            "certificate_validity_date_start": "20230815",
            "certificate_validity_date_end": "20330815",
            "certificate_photo_front": "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7",
            "certificate_photo_back": "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7"
        }
    },
    "icp_applets": {
        "base_info": {
            "service_content_types": [
                3,
                4
            ], // 工具 & 家政
            "nrlx_details": [
                {
                    "type": 24 // 以上都不涉及
                }
            ],
            "comment": "小程序备注"
        },
        "principal_info": {
            "name": "王五",
            "mobile": "13011111111",
            "email": "wangwu@lisiwangwucorp.com",
            "emergency_contact": "15522223333",
            "certificate_type": 2, // 身份证
            "certificate_number": "440105199001019999",
            "certificate_validity_date_start": "20230815",
            "certificate_validity_date_end": "20330815",
            "certificate_photo_front": "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7",
            "certificate_photo_back": "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7",
            "verify_task_id": "R5PqRPNb6GmG3i0rqd4pTg"
        }
    },
    "icp_materials": {
        "commitment_letter": [
            "4ahCGpd3CYkE6RpkNkUR5czt3LvG8xDnDdKAz6bBKttSfM8p4k5Rj6823HXugPwQBurgMezyib7"
        ]
    }
}
 */
/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      loading: false,
      appid: "",
      form: {
        icp_subject: {
          base_info: {
            type: 4,
            name: "",
            province: "",
            city: "",
            district: "",
            address: "",
            comment: "",
          },
          organize_info: {
            certificate_type: 1,
            certificate_number: "",
            certificate_address: "",
            certificate_photo: "",
          },
          principal_info: {
            name: "",
            mobile: "",
            email: "",
            emergency_contact: "",
            certificate_type: "",
            certificate_number: "",
            certificate_validity_date_start: "",
            certificate_validity_date_end: "",
            certificate_photo_front: "",
            certificate_photo_back: "",
            authorization_letter: "",
          },
          legal_person_info: {
            name: "",
            certificate_number: "",
          },
        },
        icp_applets: {
          base_info: {
            service_content_types: [],
            nrlx_details: [{ type: 24 }],
          },
          principal_info: {
            name: "",
            mobile: "",
            email: "",
            emergency_contact: "",
            certificate_type: "",
            certificate_number: "",
            certificate_validity_date_start: "",
            certificate_validity_date_end: "",
            certificate_photo_front: "",
            certificate_photo_back: "",
            verify_task_id: "",
          },
        },
        icp_materials: {
          commitment_letter: [""],
        },
      },
      provinceOptions: [], // 您提供的省市区数据
      cityOptions: [],
      districtOptions: [],
      enterpriseTypes: [],
      certificateTypes: [],
      contentTypes: [],
      nrlxDetails: [],
      imageUrl: {
        certificate_photo: "",
        certificate_photo_back: "",
        certificate_photo_front: "",
        authorization_letter: "",
        certificate_photo_back2: "",
        certificate_photo_front2: "",
        authorization_letter2: "",
        nrlx_details: "",
      },
      legalset: "1",
      adminset: "0",
      activeName: "videocheck",
      isUploading: false,
      icp_verifytask: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.icp.micoapp.text");
    this.items = [
      {
        text: this.$t("menuitems.icp.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.icp.micoapp.text"),
        active: true,
      },
    ];
    this.appid = this.$route.params.appid;
    this.getinitdata();
  },
  methods: {
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    getinitdata() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(that.apiuri, {
          action: "geticpinitdata",
          appid: that.appid,
        })
        .then(function (response) {
          that.loading = false;
          that.contentTypes = response.data.servicetype;
          that.enterpriseTypes = response.data.subjecttypes;
          that.certificateTypes = response.data.certificatetypes;
          that.provinceOptions = response.data.localdata;
          that.nrlxDetails = response.data.nrlxDetails;
          if(response.data.info.length>0) that.form = response.data.info;
          
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    certificate_photo_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.certificate_photo = params[0] + "," + params[1];
          let field = "icp_subject.organize_info.certificate_photo";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_subject.organize_info.certificate_photo = media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    certificate_photo_front_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.certificate_photo_front = params[0] + "," + params[1];
          let field = "icp_subject.principal_info.certificate_photo_front";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_subject.principal_info.certificate_photo_front =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    certificate_photo_back_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.certificate_photo_back = params[0] + "," + params[1];
          let field = "icp_subject.principal_info.certificate_photo_back";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_subject.principal_info.certificate_photo_back =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    authorization_letter_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.authorization_letter = params[0] + "," + params[1];
          let field = "icp_subject.principal_info.authorization_letter";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_subject.principal_info.authorization_letter =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    certificate_photo_front2_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.certificate_photo_front = params[0] + "," + params[1];
          let field = "icp_applets.principal_info.certificate_photo_front";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_applets.principal_info.certificate_photo_front =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    certificate_photo_back2_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.certificate_photo_back = params[0] + "," + params[1];
          let field = "icp_applets.principal_info.certificate_photo_back";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_applets.principal_info.certificate_photo_back =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    authorization_letter2_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.authorization_letter = params[0] + "," + params[1];
          let field = "icp_applets.principal_info.authorization_letter";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_applets.principal_info.authorization_letter =
                media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    nrlx_details_upload(file, fileList) {
      if (this.isUploading) {
        return;
      }

      this.isUploading = true;
      this.getBase64(file.raw).then((res) => {
        const params = res.split(",");
        if (params.length > 0) {
          this.imageUrl.nrlx_details = params[0] + "," + params[1];
          let field = "icp_applets.base_info.nrlx_details.media";
          this.uploadimages(params[1], field)
            .then((media_id) => {
              this.form.icp_applets.base_info.nrlx_details.media = media_id;
            })
            .catch((error) => {
              // 处理错误
              console.error(error);
            })
            .finally(() => {
              this.isUploading = false;
            });
        }
      });
    },
    uploadimages(uri, field) {
      return new Promise((resolve, reject) => {
        var that = this;
        that.loading = true;
        that.$axios
          .post("https://api.aicoiot.com/componentapi.php", {
            action: "uploadimages",
            appid: that.appid,
            uri: uri,
            field: field,
            certificate: that.form.icp_subject.organize_info.certificate_type,
          })
          .then(function (response) {
            that.loading = false;
            resolve(response.data.media_id);
          })
          .catch(function (error) {
            console.log(error);
            that.loading = false;
            reject(error);
          });
      });
    },
    copyadmininfo() {
      this.form.icp_applets.principal_info =
        this.form.icp_subject.principal_info;
      this.imageUrl.certificate_photo_front2 =
        this.imageUrl.certificate_photo_front;
      this.imageUrl.certificate_photo_back2 =
        this.imageUrl.certificate_photo_back;
      this.imageUrl.authorization_letter2 = this.imageUrl.authorization_letter;
    },
    handleProvinceChange(value) {
      // 根据选择的省份更新城市选项
      const province = this.findItemByCode(this.provinceOptions, value);

      this.province = "";
      this.city = "";
      this.district = "";

      if (province) {
        this.cityOptions = province.sub_list;
        this.districtOptions = [];
        this.form.icp_subject.base_info.city = [];
        this.form.icp_subject.base_info.district = [];
      }
      if (!value) {
        this.districtOptions = [];
        this.form.icp_subject.base_info.city = [];
        this.form.icp_subject.base_info.district = [];
      }
    },
    handleCityChange(value) {
      // 根据选择的城市更新区县选项
      const city = this.findItemByCode(this.cityOptions, value);
      this.city = "";
      this.district = "";
      if (city) {
        this.districtOptions = city.sub_list;
        this.form.icp_subject.base_info.district = [];
      }
    },
    handleBaseTypeChange(value) {
      this.form.icp_subject.organize_info.certificate_type = "";
    },
    findItemByCode(list, code) {
      return list.find((item) => item.code === code);
    },
    make_principal_task() {
      var that = this
      that.loading = true;
      that.$axios
        .post("https://api.aicoiot.com/componentapi.php", {
          action: "createIcpVerifyTask",
          appid: that.appid,
        })
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.form.icp_applets.principal_info.verify_task_id = response.data.task_id
            that.icp_verifytask = true

            that.$message({
              message: "创建成功",
              type: "success",
            });
          } else {
            that.$message({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    query_principal_task() {

    },
    submitForm() {
      var that = this;
      if (!that.form.icp_subject.base_info.type) {
        that.$message({ message: "请选择主体类型", type: "error" });
        return;
      }
      if (!that.form.icp_subject.organize_info.certificate_type) {
        that.$message({ message: "请选择证件类型", type: "error" });
        return;
      }
      if (
        !that.form.icp_subject.organize_info.certificate_photo &&
        that.form.icp_subject.base_info.type != 5
      ) {
        that.$message({ message: "请上传营业执照", type: "error" });
        return;
      }
      if (!that.form.icp_subject.base_info.name) {
        that.$message({ message: "请填写主体名称", type: "error" });
        return;
      }
      if (!that.form.icp_subject.organize_info.certificate_number) {
        that.$message({ message: "请填写证件号码", type: "error" });
        return;
      }
      if (!that.form.icp_subject.organize_info.certificate_address) {
        that.$message({ message: "请填写证件地址", type: "error" });
        return;
      }
      if (!that.form.icp_subject.base_info.province) {
        that.$message({ message: "请选择所在省份", type: "error" });
        return;
      }
      if (!that.form.icp_subject.base_info.city) {
        that.$message({ message: "请选择所在城市", type: "error" });
        return;
      }
      if (!that.form.icp_subject.base_info.district) {
        that.$message({ message: "请选择所在区县", type: "error" });
        return;
      }
      if (!that.form.icp_subject.base_info.address) {
        that.$message({ message: "请填写通讯地址", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.name) {
        that.$message({ message: "请填写主体名称", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.mobile) {
        that.$message({ message: "请填写手机号", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.email) {
        that.$message({ message: "请填写邮箱", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.emergency_contact) {
        that.$message({ message: "请填写紧急联系方式", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.certificate_type) {
        that.$message({ message: "请选择证件类型", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.certificate_number) {
        that.$message({ message: "请填写证件号码", type: "error" });
        return;
      }
      if (
        !that.form.icp_subject.principal_info.certificate_validity_date_start
      ) {
        that.$message({ message: "请选择证件有效期开始日期", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.certificate_validity_date_end) {
        that.$message({ message: "请选择证件有效期结束日期", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.certificate_photo_front) {
        that.$message({ message: "请上传证件照片正面", type: "error" });
        return;
      }
      if (!that.form.icp_subject.principal_info.certificate_photo_back) {
        that.$message({ message: "请上传证件照片背面", type: "error" });
        return;
      }
      if (
        !that.form.icp_subject.principal_info.authorization_letter &&
        that.legalset == 0
      ) {
        that.$message({ message: "请上传授权委托书", type: "error" });
        return;
      }
      if (!that.form.icp_applets.base_info.service_content_types) {
        that.$message({ message: "请选择服务内容", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.name) {
        that.$message({ message: "请填写姓名", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.mobile) {
        that.$message({ message: "请填写手机号", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.email) {
        that.$message({ message: "请填写邮箱", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.emergency_contact) {
        that.$message({ message: "请填写紧急联系方式", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.certificate_type) {
        that.$message({ message: "请选择证件类型", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.certificate_number) {
        that.$message({ message: "请填写证件号码", type: "error" });
        return;
      }
      if (
        !that.form.icp_applets.principal_info.certificate_validity_date_start
      ) {
        that.$message({ message: "请选择证件有效期开始日期", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.certificate_validity_date_end) {
        that.$message({ message: "请选择证件有效期结束日期", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.certificate_photo_front) {
        that.$message({ message: "请上传证件照片正面", type: "error" });
        return;
      }
      if (!that.form.icp_applets.principal_info.certificate_photo_back) {
        that.$message({ message: "请上传证件照片背面", type: "error" });
        return;
      }
      if (
        !that.form.icp_applets.principal_info.authorization_letter &&
        that.legalset == 0
      ) {
        that.$message({ message: "请上传授权委托书", type: "error" });
        return;
      }
      if (
        that.form.icp_subject.principal_info.mobile ===
        that.form.icp_subject.principal_info.emergency_contact
      ) {
        that.$message({
          message: "紧急联系方式不能与手机号相同",
          type: "error",
        });
        return;
      }
      if (
        that.form.icp_subject.principal_info.name !=
        that.form.icp_subject.principal_info.name ||
        that.form.icp_subject.principal_info.name !=
        that.form.icp_applets.principal_info.name
      ) {
        that.$message({
          message: "个人主体管理员姓名必须与主体名称相同",
          type: "error",
        });
        return;
      }
      if (that.legalset) {
        delete that.form.icp_subject.legal_person_info;
        delete that.form.icp_applets.principal_info.authorization_letter;
        delete that.form.icp_subject.principal_info.authorization_letter;
      }
      that.loading = true;

      that.$axios
        .post("https://api.aicoiot.com/componentapi.php", {
          action: "submiticpdata",
          appid: that.appid,
          form: that.form,
        })
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: "备案申请已提交，48小时内微信官方将对资料进行审核，请保证主体负责人与小程序负责人的手机号与紧急联系方式可以接通电话",
              type: "success",
              duration: 0,
              showClose: true,
            });
            that.$router.push({
              path: "/icp/micoapp",
            });
          } else {
            that.$message({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async handleUploadSuccess(field, file) {
      // Handle the uploaded file
      console.log("Uploaded file:", file);
      try {
        // Send a POST request to the specified API endpoint
        const response = await axios.post(
          "https://api.aicoiot.com/componentapi.php",
          {
            action: uploadicpmedia,
            file: file.raw,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Extract the media_id from the response
        const mediaId = response.data.media_id;

        // Update the form data with the media_id as a string
        if (field === "certificate_photo") {
          this.form.icp_subject.organize_info.certificate_photo = mediaId;
        } else if (field === "certificate_photo_front") {
          this.form.icp_subject.principal_info.certificate_photo_front =
            mediaId;
        } else if (field === "certificate_photo_back") {
          this.form.icp_subject.principal_info.certificate_photo_back = mediaId;
        } else if (field === "commitment_letter") {
          this.form.icp_materials.commitment_letter = mediaId;
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
  },
  computed: {
    filteredCertificateTypes() {
      return this.certificateTypes.filter((certificateTypeOption) => {
        return (
          certificateTypeOption.subject_type ===
          this.form.icp_subject.base_info.type
        );
      });
    },
    filteredCertificateTypesPes() {
      return this.certificateTypes.filter((certificateTypeOption) => {
        return certificateTypeOption.subject_type === 5;
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-form :model="form" ref="form" label-width="140px">
              <!-- ICP Subject Section -->
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="管理员视频核身" name="videocheck">
                  <el-form-item label="管理员核身">
                    <el-button type="info" v-if="!icp_verifytask && !form.icp_applets.principal_info.verify_task_id" round
                      @click="make_principal_task">点击开始</el-button>
                    <el-button type="info" v-else-if="icp_verifytask && form.icp_applets.principal_info.verify_task_id"
                      round @click="query_principal_task">查询状态</el-button>
                    <el-button type="success" v-else round>已开始</el-button>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item title="主体信息" name="base">
                  <el-form-item label="主体类型">
                    <el-select v-model="form.icp_subject.base_info.type" @change="handleBaseTypeChange">
                      <el-option v-for="typeOption in enterpriseTypes" :key="typeOption.type" :label="typeOption.name"
                        :value="typeOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="证件类型">
                    <el-select v-model="form.icp_subject.organize_info.certificate_type">
                      <el-option key="0" label="请选择" value="0"></el-option>
                      <el-option v-for="certificateTypeOption in filteredCertificateTypes"
                        :key="certificateTypeOption.type" :label="certificateTypeOption.name"
                        :value="certificateTypeOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="营业执照" v-if="form.icp_subject.base_info.type != 5">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="certificate_photo_upload">
                      <img v-if="imageUrl.certificate_photo" :src="imageUrl.certificate_photo" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="主体名称">
                    <el-input v-model="form.icp_subject.base_info.name"></el-input>
                  </el-form-item>
                  <el-form-item label="证件号码">
                    <el-input v-model="form.icp_subject.organize_info.certificate_number
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="证件地址">
                    <el-input v-model="form.icp_subject.organize_info.certificate_address
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="所在省份">
                    <el-select v-model="form.icp_subject.base_info.province" @change="handleProvinceChange">
                      <el-option key="0" label="请选择" value="0"></el-option>
                      <el-option v-for="provinceOption in provinceOptions" :key="provinceOption.code"
                        :label="provinceOption.name" :value="provinceOption.code"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所在城市" v-if="form.icp_subject.base_info.province">
                    <el-select v-model="form.icp_subject.base_info.city" @change="handleCityChange">
                      <el-option key="0" label="请选择" value="0"></el-option>
                      <el-option v-for="cityOption in cityOptions" :key="cityOption.code" :label="cityOption.name"
                        :value="cityOption.code"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所在区县" v-if="form.icp_subject.base_info.province && form.icp_subject.base_info.city">
                    <el-select v-model="form.icp_subject.base_info.district">
                      <el-option key="0" label="请选择" value="0"></el-option>
                      <el-option v-for="districtOption in districtOptions" :key="districtOption.code"
                        :label="districtOption.name" :value="districtOption.code"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="通讯地址">
                    <el-input v-model="form.icp_subject.base_info.address"></el-input>
                  </el-form-item>
                  <!-- ... 其他字段 ... -->
                </el-collapse-item>
                <el-collapse-item title="负责人信息" name="admin">
                  <el-form-item label="负责人是否为法人" v-if="form.icp_subject.base_info.type != 5">
                    <el-radio v-model="legalset" label="1" val>法定代表人</el-radio>
                    <el-radio v-model="legalset" label="0">指定负责人</el-radio>
                  </el-form-item>
                  <el-form-item label="证件类型">
                    <el-select v-model="form.icp_subject.principal_info.certificate_type">
                      <el-option v-for="certificateTypeOption in filteredCertificateTypesPes"
                        :key="certificateTypeOption.type" :label="certificateTypeOption.name"
                        :value="certificateTypeOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="姓名">
                    <el-input v-model="form.icp_subject.principal_info.name"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号">
                    <el-input v-model="form.icp_subject.principal_info.mobile"></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱">
                    <el-input v-model="form.icp_subject.principal_info.email"></el-input>
                  </el-form-item>
                  <el-form-item label="紧急联系方式">
                    <el-input v-model="form.icp_subject.principal_info.emergency_contact
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="证件号码">
                    <el-input v-model="form.icp_subject.principal_info.certificate_number
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="证件有效期">
                    <el-date-picker v-model="form.icp_subject.principal_info
                      .certificate_validity_date_start
                      " type="date" placeholder="开始日期" value-format="yyyyMMdd" style="width: 100%"></el-date-picker>
                    <el-date-picker v-model="form.icp_subject.principal_info
                      .certificate_validity_date_end
                      " type="date" placeholder="结束日期" value-format="yyyyMMdd" style="width: 100%"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="身份证正面">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="certificate_photo_front_upload">
                      <img v-if="imageUrl.certificate_photo_front" :src="imageUrl.certificate_photo_front"
                        class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="身份证反面">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="certificate_photo_back_upload">
                      <img v-if="imageUrl.certificate_photo_back" :src="imageUrl.certificate_photo_back" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="法人姓名" v-if="legalset == '0'">
                    <el-input v-model="form.icp_subject.legal_person_info.name"></el-input>
                  </el-form-item>
                  <el-form-item label="法人身份证号" v-if="legalset == '0'">
                    <el-input v-model="form.icp_subject.legal_person_info.certificate_number
                      "></el-input>
                  </el-form-item>

                  <el-form-item label="授权书" v-if="legalset == '0'">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="authorization_letter_upload">
                      <img v-if="imageUrl.authorization_letter" :src="imageUrl.authorization_letter" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item title="小程序信息" name="app">
                  <el-form-item label="内容类型">
                    <el-select v-model="form.icp_applets.base_info.service_content_types" multiple filterable
                      :multiple-limit="5">
                      <el-option v-for="contentTypeOption in contentTypes" :key="contentTypeOption.type"
                        :label="contentTypeOption.name" :value="contentTypeOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="前置审批类型">
                    <el-select v-for="(val, idx) in form.icp_applets.base_info.nrlx_details" :key="val.type"
                      v-model="form.icp_applets.base_info.nrlx_details[idx].type">
                      <el-option v-for="nrlxDetailOption in nrlxDetails" :key="nrlxDetailOption.type"
                        :label="nrlxDetailOption.name" :value="nrlxDetailOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="前置审批号" v-if="form.icp_applets.base_info.nrlx_details.code &&
                    form.icp_applets.base_info.nrlx_details.code != 24
                    ">
                    <el-input v-model="form.icp_applets.base_info.nrlx_details.code"></el-input>
                  </el-form-item>
                  <el-form-item label="证件图片" v-if="form.icp_applets.base_info.nrlx_details.code &&
                    form.icp_applets.base_info.nrlx_details.code != 24
                    ">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="nrlx_details_upload">
                      <img v-if="imageUrl.nrlx_details" :src="imageUrl.nrlx_details" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="小程序描述">
                    <el-input v-model="form.icp_applets.base_info.comment"></el-input>
                  </el-form-item>
                </el-collapse-item>
                <el-collapse-item title="小程序负责人" name="admin2">
                  <el-form-item label="复用信息">
                    <el-button type="primary" @click="copyadmininfo">复用主体管理员信息</el-button>
                  </el-form-item>
                  <el-alert title="备案要求小程序管理员必须为备案中小程序负责人，如需更换请先对小程序管理员进行变更" type="warning">
                  </el-alert>
                  <el-form-item label="证件类型">
                    <el-select v-model="form.icp_applets.principal_info.certificate_type">
                      <el-option v-for="certificateTypeOption in filteredCertificateTypesPes"
                        :key="certificateTypeOption.type" :label="certificateTypeOption.name"
                        :value="certificateTypeOption.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="姓名">
                    <el-input v-model="form.icp_applets.principal_info.name"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号">
                    <el-input v-model="form.icp_applets.principal_info.mobile"></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱">
                    <el-input v-model="form.icp_applets.principal_info.email"></el-input>
                  </el-form-item>
                  <el-form-item label="紧急联系方式">
                    <el-input v-model="form.icp_applets.principal_info.emergency_contact
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="证件号码">
                    <el-input v-model="form.icp_applets.principal_info.certificate_number
                      "></el-input>
                  </el-form-item>
                  <el-form-item label="证件有效期">
                    <el-date-picker v-model="form.icp_applets.principal_info
                      .certificate_validity_date_start
                      " type="date" placeholder="开始日期" value-format="yyyyMMdd" style="width: 100%"></el-date-picker>
                    <el-date-picker v-model="form.icp_applets.principal_info
                      .certificate_validity_date_end
                      " type="date" placeholder="结束日期" value-format="yyyyMMdd" style="width: 100%"></el-date-picker>
                  </el-form-item>
                  <el-form-item label="身份证正面">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="certificate_photo_front_upload">
                      <img v-if="imageUrl.certificate_photo_front2" :src="imageUrl.certificate_photo_front2"
                        class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="身份证反面">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="certificate_photo_back_upload">
                      <img v-if="imageUrl.certificate_photo_back2" :src="imageUrl.certificate_photo_back2"
                        class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="授权书" v-if="legalset == '0'">
                    <el-upload class="avatar-uploader" action="#" accept=".jpg" :show-file-list="false"
                      :on-change="authorization_letter2_upload">
                      <img v-if="imageUrl.authorization_letter2" :src="imageUrl.authorization_letter2" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-form-item>
                </el-collapse-item>
              </el-collapse>

              <!-- Submit Button -->
              <el-form-item>
                <el-button type="primary" @click="submitForm">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>